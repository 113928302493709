/* eslint-disable max-len */
import { IContext, ILocalization } from '../types.d';

export class ConfirmationModal {
    private confirmPlaceHolder: HTMLElement;
    private assetsLoaded: boolean;
    private closeOverlayCallBack: () => void;
    private afterCloseOverlay: () => void;
    public designerId = 'smake-designer';
    private context: IContext;

    public constructor(closeOverlayCallBack: () => void, afterCloseOverlay: () => void) {
        this.assetsLoaded = false;
        this.closeOverlayCallBack = closeOverlayCallBack;
        this.afterCloseOverlay = afterCloseOverlay;
        this.context = window.__PP_LC_CONTEXT__;
    }

    public update(closeOverlayCallBack: () => void, afterCloseOverlay: () => void): void {
        this.closeOverlayCallBack = closeOverlayCallBack;
        this.afterCloseOverlay = afterCloseOverlay;
    }

    public showOverlayModal(): void {
        if (!this.assetsLoaded) {
            // append main window:
            this.createDesignerElement();
            this.assetsLoaded = true;
        } else {
            this.confirmPlaceHolder.classList.remove('lc-overlay-closed');
        }
    }

    private createDesignerElement(): void {
        const localizations: ILocalization = this.context.localization;
        this.confirmPlaceHolder = document.createElement('div');
        this.confirmPlaceHolder.classList.add('lc-confirm-background');

        this.confirmPlaceHolder.innerHTML = `<div class="lc-confirm-modal">
                <div class="lc-confirm-header"><span>${localizations.notic}</span></div>
                <div class="lc-confirm-content"><span>${localizations.configurationWillBeLost}</span></div>
                <div class="lc-confirm-footer">
                    <button class="lc-confirm-button lc-confirm-cancel">${localizations.cancel}</button>
                    <button class="lc-confirm-button lc-confirm-close">${localizations.close}</button>
                </div>
            </div>`;

        document.body.appendChild(this.confirmPlaceHolder);

        const lcConfirmButtons = document.querySelectorAll('.lc-confirm-button');

        if (lcConfirmButtons.length > 0) {
            lcConfirmButtons.forEach((button) => {
                button.addEventListener('click', (event) => {
                    const targetButton = event.target as HTMLElement;
                    if (targetButton.classList.contains('lc-confirm-close')) {
                        this.closeOverlayCallBack();
                        this.afterCloseOverlay();
                    }
                    this.confirmPlaceHolder.classList.add('lc-overlay-closed');
                })
            });
        }
    }
}
