/* eslint-disable max-len */
import { ISmakevailableVariant } from '../logoCreaterService/logoCreaterService.d';
import { IContext, ILocalization, ISmakeArticle } from '../types.d';

export class TransforToCartOverlayModal {
    private ttcPlaceHolder: HTMLElement;
    private assetsLoaded: boolean;
    private context: IContext;
    public ttcId = 'transfer-to-cart';

    private lcContentElement: Element;
    private ttcElement: Element;

    private tryToClose: (callBack: () => void) => void;
    public constructor(public smakeId: number,
        private addedArticles: ISmakeArticle[],
        private availableSizes: ISmakevailableVariant[],
        private preselectedSmakeArticle: ISmakeArticle[],
        private userId: string,
        private smakeToken: string,
        private vatRate: number,
        tryToClose: () => void) {
        this.assetsLoaded = false;
        this.context = window.__PP_LC_CONTEXT__;
        this.tryToClose = tryToClose;
    }

    public showOverlayModal(): void {
        if (!this.assetsLoaded) {
            // append main window:
            this.createDesignerElement();
            this.setupTTC();
            this.assetsLoaded = true;

            /*this is the hack for now until smake provide npm package for TTC*/
            const newCode = document.createElement('script');
            newCode.text = 'TransferToCartComponent.register()';
            document.body.appendChild(newCode);
            /****/
        } else {
            this.ttcPlaceHolder.classList.remove('lc-overlay-closed');
        }

        if (this.lcContentElement) {
            this.ttcElement = this.getSmakeElement();
            this.lcContentElement.append(this.ttcElement);
        }

        const ttc = document.querySelector(`#${this.ttcId}`);
        if (ttc) {
            ttc.addEventListener('step-changed', () => {
                const container = document.querySelector('.lc-ttc-container .lc-content');
                container.scrollTop = 0;
            });
        }
    }

    public update(smakeId: number, psArticles: ISmakeArticle[], availableSizes: ISmakevailableVariant[], preselectedSmakeArticle: ISmakeArticle[], userId: string, smakeToken: string, vatRate: number) {
        this.addedArticles = psArticles;
        this.smakeId = smakeId;
        this.availableSizes = availableSizes;
        this.preselectedSmakeArticle = preselectedSmakeArticle;
        this.userId = userId;
        this.vatRate = vatRate;
        this.smakeToken = smakeToken;
    }

    public closeOverlayModal(): void {
        // remove modal element from main windows on close:
        this.ttcPlaceHolder.classList.add('lc-overlay-closed');
        this.ttcElement.remove();
        this.ttcElement = null;
    }

    private createDesignerElement(): void {
        const localizations: ILocalization = this.context.localization;
        this.ttcPlaceHolder = document.createElement('div');
        this.ttcPlaceHolder.classList.add('lc-ttc-container');

        this.ttcPlaceHolder.innerHTML = `
        <div class="lc-header">
           <svg version="1.1" class="lc-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1388 340" style="enable-background:new 0 0 1388 340;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#E30613;}
	.st1{fill:#FFFFFF;}
</style>
<g id="TEXT">
	<g id="ohne_Subline">
		<g id="CI_Configurator">
			<g>
				<path d="M386.1,104c0.6,0,1,0.3,1.4,0.9l8,11.8c0.4,0.5,0.7,1,0.7,1.4c0,0.5-0.4,1.1-1.3,1.7c-6.3,4.4-13.4,6.6-21.1,6.6
					c-6.8,0-13.1-1.7-18.9-5.1s-10.3-7.9-13.7-13.7c-3.4-5.8-5-12.1-5-18.9s1.7-13.1,5-18.8s7.9-10.2,13.7-13.6c5.8-3.3,12-5,18.9-5
					c4.1,0,8,0.6,11.6,1.9c3.6,1.2,6.8,2.9,9.7,5c0.6,0.5,0.9,1,0.9,1.7c0,0.3-0.1,0.7-0.2,0.9l-8.5,12.2c-0.3,0.5-0.8,0.7-1.4,0.7
					c-0.5,0-1.1-0.2-1.6-0.7c-1.6-1.3-3.3-2.2-4.9-2.8c-1.6-0.6-3.5-0.9-5.5-0.9c-3.6,0-6.8,0.9-9.7,2.6c-2.9,1.7-5.1,4.1-6.8,7
					c-1.6,2.9-2.5,6.2-2.5,9.8s0.8,6.9,2.5,9.8c1.6,3,3.9,5.4,6.8,7.1c2.9,1.7,6.1,2.6,9.6,2.6s6.8-1.2,10.3-3.5
					C384.9,104.3,385.6,104,386.1,104L386.1,104z"/>
				<path d="M412,54.8c0-1.5,0.7-2.2,2.1-2.2h14.6c1.4,0,2.1,0.7,2.1,2.2v68.4c0,1.4-0.7,2.1-2.1,2.1h-14.6c-1.4,0-2.1-0.7-2.1-2.1
					V54.8z"/>
				<path d="M531.9,104c0.6,0,1,0.3,1.4,0.9l8,11.8c0.4,0.5,0.7,1,0.7,1.4c0,0.5-0.4,1.1-1.3,1.7c-6.3,4.4-13.4,6.6-21.1,6.6
					c-6.8,0-13.1-1.7-18.9-5.1s-10.3-7.9-13.7-13.7c-3.4-5.8-5-12.1-5-18.9s1.7-13.1,5-18.8c3.4-5.7,7.9-10.2,13.7-13.6
					c5.8-3.3,12-5,18.9-5c4.2,0,8,0.6,11.6,1.9c3.6,1.2,6.8,2.9,9.7,5c0.6,0.5,0.9,1,0.9,1.7c0,0.3-0.1,0.7-0.3,0.9L533,73.2
					c-0.3,0.5-0.8,0.7-1.4,0.7c-0.5,0-1.1-0.2-1.6-0.7c-1.6-1.3-3.3-2.2-4.9-2.8c-1.6-0.6-3.5-0.9-5.5-0.9c-3.6,0-6.8,0.9-9.7,2.6
					c-2.9,1.7-5.1,4.1-6.8,7c-1.6,2.9-2.5,6.2-2.5,9.8s0.8,6.9,2.5,9.8c1.6,3,3.9,5.4,6.8,7.1c2.9,1.7,6.1,2.6,9.7,2.6
					s6.8-1.2,10.3-3.5C530.7,104.3,531.4,104,531.9,104L531.9,104z"/>
				<path d="M554.2,70.2c3.4-5.7,7.9-10.3,13.7-13.7c5.7-3.4,12-5,18.8-5s13,1.7,18.8,5c5.7,3.4,10.3,7.9,13.7,13.7
					c3.4,5.7,5,12,5,18.8s-1.7,13-5,18.8c-3.4,5.7-7.9,10.3-13.7,13.6c-5.7,3.3-12,5-18.8,5s-13-1.7-18.8-5
					c-5.7-3.3-10.3-7.9-13.7-13.6c-3.4-5.7-5-12-5-18.8S550.8,76,554.2,70.2z M570.4,98.8c1.6,3,3.8,5.3,6.7,7
					c2.8,1.7,6,2.6,9.5,2.6s6.7-0.9,9.6-2.6c2.8-1.7,5.1-4.1,6.7-7s2.4-6.2,2.4-9.8s-0.8-6.8-2.4-9.8s-3.8-5.3-6.7-7
					c-2.8-1.7-6-2.6-9.6-2.6s-6.7,0.9-9.5,2.6s-5.1,4.1-6.7,7S568,85.4,568,89S568.8,95.8,570.4,98.8z"/>
				<path d="M658.1,123.2c0,1.4-0.8,2.1-2.4,2.1H642c-1.4,0-2.1-0.7-2.1-2.1V54.8c0-0.6,0.2-1.1,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6
					h15.9c1.4,0,2.5,0.7,3.5,2.2l24.5,39.7V54.8c0-0.6,0.2-1.1,0.6-1.6c0.4-0.4,0.9-0.6,1.6-0.6h13.6c0.5,0,1.1,0.2,1.6,0.6
					c0.5,0.4,0.8,0.9,0.8,1.6v68.4c0,1.4-0.8,2.1-2.4,2.1h-14.3c-1.5,0-2.6-0.7-3.5-2.1l-25.8-42.4L658.1,123.2L658.1,123.2z"/>
				<path d="M722.3,54.8c0-1.5,0.7-2.2,2.1-2.2h46.7c1.4,0,2.1,0.7,2.1,2.2v12.6c0,1.4-0.7,2.1-2.1,2.1h-29.9v15.1h21.9
					c1.4,0,2.1,0.7,2.1,2.2v11.9c0,1.4-0.7,2.1-2.1,2.1h-21.9v22.4c0,1.4-0.7,2.1-2.1,2.1h-14.7c-1.4,0-2.1-0.7-2.1-2.1V54.8z"/>
				<path d="M789.3,54.8c0-1.5,0.7-2.2,2.1-2.2h14.7c1.4,0,2.1,0.7,2.1,2.2v68.4c0,1.4-0.7,2.1-2.1,2.1h-14.7
					c-1.4,0-2.1-0.7-2.1-2.1V54.8z"/>
				<path d="M869.3,107.4v-13c0-1.4,0.7-2.1,2.1-2.1h13.7c0.5,0,1,0.2,1.5,0.5s0.6,0.9,0.6,1.7v23.7c0,0.5-0.1,1-0.2,1.3
					c-0.2,0.4-0.5,0.7-0.9,0.9c-3,1.9-6.5,3.4-10.5,4.5s-8.2,1.6-12.7,1.6c-6.8,0-13.1-1.7-18.9-5.1s-10.3-7.9-13.7-13.7
					c-3.4-5.8-5.1-12.1-5.1-18.9s1.7-13.1,5-18.8c3.3-5.7,7.9-10.2,13.6-13.5s12-5,18.9-5c4.3,0,8.1,0.6,11.5,1.7s6.7,2.8,9.8,5.1
					c0.7,0.5,1,1,1,1.6c0,0.4-0.1,0.8-0.4,1.1l-8.1,11.6c-0.4,0.6-0.9,0.9-1.5,0.9c-0.5,0-0.9-0.2-1.4-0.5
					c-3.3-2.4-6.9-3.6-10.8-3.6s-7,0.8-9.8,2.6c-2.8,1.7-5,4-6.6,7c-1.5,2.9-2.3,6.2-2.3,9.8s0.8,6.8,2.4,9.8c1.6,3,3.8,5.4,6.5,7.1
					s5.9,2.6,9.4,2.6C864.9,108.3,867.2,108,869.3,107.4L869.3,107.4z"/>
				<path d="M923.9,96.3c0,3.1,0.5,5.6,1.6,7.6s2.5,3.4,4.3,4.4c1.8,0.9,3.8,1.4,6,1.4s4.2-0.5,6.1-1.6c1.8-1,3.3-2.6,4.4-4.6
					s1.6-4.4,1.6-7.2V54.8c0-0.6,0.2-1.1,0.6-1.6c0.4-0.4,1-0.6,1.6-0.6h14.3c0.8,0,1.3,0.2,1.8,0.6c0.4,0.4,0.6,0.9,0.6,1.6v41.3
					c0,7.2-1.4,13.1-4.2,17.7c-2.8,4.5-6.5,7.8-11.1,9.8c-4.6,2-9.9,3-15.7,3s-11.1-1-15.7-3c-4.6-2-8.3-5.3-11.1-9.8
					s-4.2-10.4-4.2-17.6V54.8c0-0.6,0.2-1.1,0.5-1.6c0.4-0.4,0.8-0.6,1.4-0.6h15c0.8,0,1.4,0.2,1.7,0.6c0.3,0.4,0.4,0.9,0.4,1.6
					L923.9,96.3L923.9,96.3z"/>
				<path d="M984.9,54.8c0-0.6,0.2-1.1,0.5-1.5s0.9-0.7,1.6-0.7h32.5c4.5,0,8.5,1.2,12,3.5c3.5,2.3,6.3,5.4,8.3,9.1
					c2,3.8,3,7.7,3,11.8c0,3.8-1.1,7.6-3.3,11.2s-5.1,6.6-8.7,8.9l14,26.1c0.2,0.3,0.3,0.6,0.3,0.9c0,0.3-0.1,0.6-0.4,0.9
					c-0.3,0.2-0.6,0.4-1.1,0.4h-17.7c-0.7,0-1.2-0.1-1.5-0.4c-0.3-0.3-0.7-0.8-1.1-1.6l-11.6-22.2h-7.9v22c0,1.4-0.7,2.1-2.1,2.1
					H987c-1.4,0-2.1-0.7-2.1-2.1L984.9,54.8L984.9,54.8z M1003.8,67.8v17.8h10.9c1.7,0,3.2-0.4,4.5-1.2c1.3-0.8,2.4-1.9,3.1-3.3
					c0.8-1.4,1.1-2.8,1.1-4.3s-0.4-2.8-1.2-4.2c-0.8-1.4-1.8-2.5-3.1-3.4c-1.3-0.9-2.8-1.3-4.4-1.3H1003.8L1003.8,67.8z"/>
				<path d="M1078.3,54.8c0.5-1.5,1.6-2.2,3-2.2h12c1.4,0,2.4,0.7,3,2.2l26.8,68.4c0.2,0.4,0.3,0.8,0.3,1c0,0.8-0.5,1.1-1.6,1.1
					h-14.6c-1.5,0-2.4-0.7-2.9-2.1l-3.4-9.6H1074l-3.3,9.6c-0.4,1.4-1.4,2.1-3,2.1h-14.6c-1.1,0-1.6-0.4-1.6-1.1
					c0-0.2,0.1-0.6,0.3-1L1078.3,54.8L1078.3,54.8z M1087.2,75.6l-7.7,22.2h15.6L1087.2,75.6z"/>
				<path d="M1122.5,54.8c0-1.5,0.7-2.2,2.1-2.2h53.2c1.4,0,2.1,0.7,2.1,2.2v12.4c0,1.5-0.7,2.2-2.1,2.2h-17.4v53.8
					c0,1.4-0.7,2.1-2.2,2.1h-14.1c-1.4,0-2.1-0.7-2.1-2.1V69.4h-17.3c-1.4,0-2.1-0.7-2.1-2.2L1122.5,54.8L1122.5,54.8z"/>
				<path d="M1191,70.2c3.4-5.7,7.9-10.3,13.7-13.7c5.7-3.4,12-5,18.8-5s13,1.7,18.8,5c5.7,3.4,10.3,7.9,13.7,13.7
					c3.4,5.7,5.1,12,5.1,18.8s-1.7,13-5.1,18.8c-3.4,5.7-7.9,10.3-13.7,13.6s-12,5-18.8,5s-13-1.7-18.8-5
					c-5.7-3.3-10.3-7.9-13.7-13.6c-3.4-5.7-5.1-12-5.1-18.8S1187.6,76,1191,70.2z M1207.2,98.8c1.6,3,3.8,5.3,6.7,7
					c2.8,1.7,6,2.6,9.5,2.6s6.7-0.9,9.6-2.6c2.8-1.7,5.1-4.1,6.7-7s2.4-6.2,2.4-9.8s-0.8-6.8-2.4-9.8s-3.8-5.3-6.7-7
					c-2.8-1.7-6-2.6-9.6-2.6s-6.7,0.9-9.5,2.6s-5.1,4.1-6.7,7c-1.6,3-2.4,6.2-2.4,9.8S1205.6,95.8,1207.2,98.8z"/>
				<path d="M1276.6,54.8c0-0.6,0.2-1.1,0.5-1.5s0.9-0.7,1.6-0.7h32.5c4.5,0,8.5,1.2,12,3.5c3.5,2.3,6.3,5.4,8.3,9.1
					c2,3.8,3,7.7,3,11.8c0,3.8-1.1,7.6-3.3,11.2s-5.1,6.6-8.7,8.9l14,26.1c0.2,0.3,0.3,0.6,0.3,0.9c0,0.3-0.1,0.6-0.4,0.9
					c-0.3,0.2-0.6,0.4-1.1,0.4h-17.7c-0.7,0-1.2-0.1-1.5-0.4c-0.3-0.3-0.7-0.8-1.1-1.6l-11.6-22.2h-7.9v22c0,1.4-0.7,2.1-2.1,2.1
					h-14.7c-1.4,0-2.1-0.7-2.1-2.1L1276.6,54.8L1276.6,54.8z M1295.5,67.8v17.8h10.9c1.7,0,3.2-0.4,4.5-1.2c1.3-0.8,2.4-1.9,3.1-3.3
					c0.8-1.4,1.1-2.8,1.1-4.3s-0.4-2.8-1.2-4.2c-0.8-1.4-1.8-2.5-3.1-3.4c-1.3-0.9-2.8-1.3-4.4-1.3H1295.5L1295.5,67.8z"/>
			</g>
		</g>
		<g id="My">
			<g>
				<path d="M369.9,284.9c-0.2,1.7-0.6,3-1.4,3.7c-0.8,0.8-2,1.1-3.6,1.1h-24.2c-1.5,0-2.7-0.5-3.6-1.5s-1.4-2.2-1.4-3.8l12.7-118.7
					c0.2-1.6,0.6-2.9,1.4-3.7c0.8-0.8,2-1.2,3.6-1.2h29.8c1.5,0,2.6,0.3,3.2,1c0.6,0.7,1.2,1.9,1.7,3.8l22,72.2l22-72.2
					c0.5-1.8,1.1-3.1,1.7-3.8c0.6-0.7,1.7-1,3.2-1h29.8c1.6,0,2.9,0.4,3.6,1.2c0.8,0.8,1.2,2.1,1.4,3.7l12.7,118.7
					c-0.1,1.5-0.5,2.8-1.4,3.8s-2.1,1.5-3.6,1.5h-24.2c-1.6,0-2.9-0.4-3.6-1.1c-0.8-0.8-1.2-2-1.4-3.7l-6.2-60.1l-18,60.1
					c-0.5,1.6-1.2,2.9-2,3.7c-0.8,0.8-2,1.2-3.4,1.2h-21.1c-1.6,0-2.9-0.3-3.6-1c-0.8-0.6-1.4-2-1.9-3.9h0.1l-17.7-61.5L369.9,284.9
					L369.9,284.9z M396.6,284.1c0.3,1.2,0.6,2,1.1,2.4s1,0.6,1.9,0.6h21.1c0.9,0,1.5-0.2,1.9-0.5c0.4-0.4,0.7-1.1,1.1-2.4l20-66.9
					h2.3l7,67.3c0.1,0.9,0.4,1.5,0.7,2c0.4,0.4,0.9,0.6,1.7,0.6h24.2c1.6,0,2.4-0.6,2.4-1.9v-0.4l-12.7-118.7
					c-0.1-0.9-0.4-1.6-0.7-2c-0.4-0.4-0.9-0.6-1.7-0.6h-29.8c-0.7,0-1.2,0.2-1.5,0.6c-0.3,0.4-0.6,1.1-1,2.2L410,246.7l-24.4-80.4
					c-0.4-1-0.7-1.7-1.1-2.2s-0.8-0.7-1.4-0.7h-29.8c-0.8,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1.1-0.7,2l-12.7,118.7v0.4
					c0,1.2,0.8,1.9,2.4,1.9h24.2c0.8,0,1.3-0.2,1.7-0.6s0.6-1.1,0.7-2l7.3-68.9h2.3L396.6,284.1L396.6,284.1z"/>
				<path d="M490,166.6c-0.5-0.9-0.8-1.7-0.8-2.6c0-1,0.4-1.8,1.1-2.4c0.8-0.6,1.8-0.8,3.2-0.8h29.1c1.5,0,2.8,0.4,3.7,1.1
					c0.9,0.7,1.8,1.9,2.6,3.5l18.8,39.9l18.6-39.9c0.7-1.6,1.5-2.8,2.5-3.5c1-0.7,2.2-1.1,3.8-1.1h29.5c1.4,0,2.4,0.3,3.2,0.8
					s1.2,1.3,1.2,2.4c0,0.8-0.3,1.7-0.8,2.6l-40.2,70.6v47.5c0,3.4-1.7,5-5,5H535c-3.4,0-5-1.7-5-5v-47.4L490,166.6L490,166.6z
					 M532.6,284.7c0,1.6,0.8,2.4,2.4,2.4h25.4c1.6,0,2.4-0.8,2.4-2.4v-48.2l40.5-71.2c0.2-0.3,0.3-0.6,0.3-0.8
					c0-0.7-0.5-1.1-1.5-1.1h-29.5c-0.9,0-1.6,0.2-2.2,0.8s-1.1,1.3-1.6,2.4v-0.1l-21.1,45.2l-21.2-45.2v0.1c-0.5-1.1-1-1.8-1.6-2.4
					c-0.6-0.5-1.3-0.8-2.2-0.8h-29.1c-1,0-1.5,0.4-1.5,1.1c0,0.2,0.1,0.5,0.3,0.8l40.3,71.4L532.6,284.7L532.6,284.7z"/>
			</g>
		</g>
		<g id="Design">
			<g>
				<path d="M652.3,166.1c0-2.5,1.2-3.8,3.7-3.8h44.1c10.5,0,20.6,2.9,30.4,8.5s17.6,13.4,23.6,23.1c6,9.7,9,20.2,9,31.6
					s-2.8,21.2-8.5,30.9c-5.7,9.7-13.3,17.5-23,23.3c-9.7,5.9-20.1,8.8-31.5,8.8h-44.1c-2.5,0-3.7-1.2-3.7-3.7V166.1L652.3,166.1z
					 M725.8,208.9c-2.9-5.1-6.9-9.1-12-12c-5.1-2.9-10.7-4.4-16.8-4.4h-11.9v66.1h11.9c6.1,0,11.8-1.5,16.8-4.4
					c5.1-2.9,9.1-6.9,12-12s4.3-10.6,4.3-16.5S728.7,214,725.8,208.9L725.8,208.9z"/>
				<path d="M790.3,166.1c0-2.5,1.2-3.8,3.7-3.8h84.6c2.5,0,3.7,1.3,3.7,3.8v21.8c0,2.5-1.2,3.7-3.7,3.7h-55.4v21h42.1
					c2.8,0,4.2,1.3,4.2,3.9V234c0,2.5-1.4,3.7-4.2,3.7h-42.1v21.7h57c2.5,0,3.8,1.2,3.8,3.7v21.7c0,2.5-1.3,3.7-3.8,3.7H794
					c-2.5,0-3.7-1.2-3.7-3.7V166.1L790.3,166.1z"/>
				<path d="M904.8,266.2c-0.4-0.7-0.6-1.4-0.6-2.1c0-1,0.5-2,1.5-3l14.7-14c0.8-0.8,1.7-1.2,2.6-1.2c1.2,0,2.5,0.6,4,1.9
					c4.5,3.8,8.9,7,13.2,9.5s8.7,3.8,13.2,3.8c5.4,0,9.1-1.1,11.1-3.2c2-2.1,3-5,3-8.7c0-2.2-0.9-4.1-2.8-5.8s-4-3-6.3-4
					s-5.1-2.1-8.4-3.2c-14-4.8-24.5-10.6-31.4-17.2c-6.9-6.6-10.3-14.8-10.3-24.4c0-6.9,1.9-13,5.8-18.3s9.3-9.4,16.2-12.3
					s15-4.4,24-4.4c5,0,10.1,0.7,15.4,2.1c5.2,1.4,10.1,3.4,14.6,5.9s8.2,5.4,11.2,8.5c1.2,1.4,1.8,2.6,1.8,3.8c0,1.3-0.7,2.6-2,4
					l-13,13.9c-0.9,0.9-1.8,1.3-2.8,1.3c-1.2,0-2.7-0.8-4.4-2.3c-1.7-1.6-3.6-3.1-5.7-4.5c-2.2-1.4-4.4-2.5-6.7-3.4
					c-2.4-0.8-5.1-1.2-8.3-1.2c-3.7,0-6.8,0.7-9.4,2c-2.6,1.4-3.9,3.5-3.9,6.5c0,2,0.8,3.7,2.3,5.1s3.6,2.7,6.3,3.9
					c2.7,1.1,6.4,2.5,11.2,4c9.3,3.1,16.7,6.1,22.4,9.2s10.1,7.1,13.4,11.9c3.3,4.9,4.9,11.1,4.9,18.6c0,8.2-2.1,15.3-6.3,21.6
					s-10,11-17.3,14.4c-7.4,3.4-15.7,5.1-24.9,5.1c-8.3,0-16.6-2-24.8-6.1c-8.2-4-15.4-9.2-21.4-15.5
					C905.8,267.7,905.2,266.9,904.8,266.2L904.8,266.2z"/>
				<path d="M1027,166.1c0-2.5,1.2-3.8,3.7-3.8h25.4c2.5,0,3.7,1.3,3.7,3.8v118.7c0,2.5-1.2,3.7-3.7,3.7h-25.4
					c-2.5,0-3.7-1.2-3.7-3.7V166.1L1027,166.1z"/>
				<path d="M1164.5,257.3v-22.5c0-2.5,1.2-3.7,3.7-3.7h23.7c0.9,0,1.8,0.3,2.5,0.8c0.7,0.5,1.1,1.5,1.1,2.9v41.1
					c0,0.9-0.2,1.7-0.4,2.4c-0.3,0.6-0.8,1.2-1.6,1.6c-5.2,3.4-11.3,6-18.2,7.8s-14.3,2.7-22,2.7c-11.8,0-22.7-2.9-32.7-8.8
					c-10-5.8-17.9-13.8-23.8-23.8c-5.9-10-8.8-20.9-8.8-32.7s2.9-22.6,8.7-32.5c5.8-9.9,13.7-17.7,23.6-23.5s20.8-8.6,32.7-8.6
					c7.4,0,14.1,1,20,2.9s11.6,4.9,17.1,8.9c1.1,0.9,1.7,1.8,1.7,2.8c0,0.6-0.2,1.3-0.6,1.9l-14.1,20.2c-0.7,1-1.5,1.5-2.7,1.5
					c-0.8,0-1.6-0.3-2.4-0.9c-5.7-4.2-11.9-6.3-18.7-6.3s-12.1,1.5-17,4.4c-4.9,2.9-8.7,7-11.4,12.1c-2.7,5.1-4,10.8-4,17
					s1.4,11.8,4.1,17c2.8,5.2,6.5,9.3,11.3,12.4c4.8,3,10.2,4.6,16.2,4.6C1156.8,259,1160.8,258.4,1164.5,257.3L1164.5,257.3z"/>
				<path d="M1257.1,284.9c0,2.5-1.4,3.7-4.2,3.7h-23.7c-2.5,0-3.7-1.2-3.7-3.7V166.1c0-1.1,0.3-2,1-2.7c0.7-0.7,1.6-1.1,2.8-1.1
					h27.5c2.4,0,4.4,1.3,6,3.8l42.6,68.9v-68.9c0-1.1,0.3-2,1-2.7c0.7-0.7,1.6-1.1,2.8-1.1h23.6c0.9,0,1.9,0.4,2.8,1.1
					c0.9,0.7,1.4,1.6,1.4,2.7v118.7c0,2.5-1.4,3.7-4.2,3.7h-24.9c-2.5,0-4.6-1.2-6.1-3.7l-44.7-73.6V284.9z"/>
			</g>
		</g>
		<path class="st0" d="M50.4,291.7V49.1c0-6.1,6.4-6.1,6.4-6.1H289c3.3-0.2,6.2,2.4,6.4,5.7c0,0.1,0,0.2,0,0.4v242.6
			c0,6.1-6.4,6.1-6.4,6.1H56.8c-3.3,0.2-6.2-2.4-6.4-5.7C50.4,292,50.4,291.8,50.4,291.7L50.4,291.7z"/>
		<path class="st1" d="M201.4,70.8c-1.5,0.4-17.7,5.1-18,5.2c-0.9,0.1-1.7,0.5-2.4,1.1c-0.6,0.8-1,1.9-1.1,2.9
			c-0.9,6.9,0.1,37.6,1.4,52.2c0,0.3,0.4,5.6,1.1,7.2c0.8,1.5,1.8,2.8,2.9,4.1c2.1,2.7,3.2,5.9,3.3,9.3c0.1,8.7-6.8,15.9-15.5,16
			c-8.7,0.1-15.9-6.8-16-15.5c0-0.1,0-0.2,0-0.3c0.1-3.4,1.2-6.8,3.3-9.5c1.1-1.3,2-2.7,2.9-4.1c0.9-2.1,1-5,1.1-7.2
			c0.6-14.2,1.4-32,1.7-41.4c0.1-7.8-1.1-16.4,0.2-24.2c0.7-6.3,6.4-10.8,12.8-10.1c1.2,0.1,2.4,0.5,3.6,1
			c6.3,2.5,11.9,6.3,17.8,9.6l0.8,0.5c2.8,1.6,1.1,2.9,0.5,3.1L201.4,70.8L201.4,70.8z M172.9,272.1c0,0-6.7,8.6-10,13
			c-0.2,0.2-0.5,0.4-0.8,0.4h-25c-0.6,0-1-0.5-1-1v-2.3c0-0.4,0.2-0.7,0.6-0.9l26.2-13.3c0,0,0.2-0.1,0.4-0.2
			c0.1-0.1,0.2-0.2,0.4-0.2c1.1-0.8,1.7-2,1.8-3.2c0-0.4-0.1-0.8-0.2-1.2c0,0-7.3-39.3-10.4-55.3c-0.2-1.2,0.6-1.9,1.6-1.8
			c5.4,1.6,10.9,2.4,16.5,2.5c5.6-0.1,11.1-0.9,16.4-2.5c1-0.2,1.8,0.6,1.6,1.8c-3.1,15.9-10.4,55.3-10.4,55.3
			c-0.1,0.4-0.1,0.8-0.2,1.2c0.1,1.3,0.8,2.5,1.8,3.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.4,0.2,0.4,0.2l26.2,13.3
			c0.3,0.2,0.6,0.5,0.6,0.9v2.3c0,0.6-0.4,1-1,1h-25c-0.3,0-0.6-0.1-0.8-0.4C179.6,280.7,172.9,272.1,172.9,272.1 M149.1,152.4
			c0.2-5,2-9.9,5.1-13.9c0,0,1.1-1.3,1.4-1.9c-3.9-13.8-12.8-24.8-23.9-33.8c-25,11.1-46.6,32.4-46.6,62c0,36.9,33.4,61.4,66.3,68
			c-1.5-8.1-4.5-23.9-4.5-23.9c-0.6-3.1,0.4-6.2,2.6-8.5c2.2-2.1,5.3-3.1,8.3-2.6c0.5,0.1,0.9,0.2,1.4,0.3c4.5,1.3,9.1,1.9,13.7,2
			c4.6-0.1,9.2-0.8,13.7-2c0.4-0.1,0.9-0.3,1.4-0.3c3-0.5,6.1,0.4,8.3,2.6c2.2,2.2,3.2,5.4,2.6,8.5c0,0-3,15.8-4.5,23.9
			c32.9-6.6,66.3-31.2,66.3-68c0-29.5-21.6-50.9-46.6-62c-11.1,9-20,20-23.9,33.8c0.4,0.5,1,1.3,1.4,1.9c3.1,4,4.9,8.8,5.1,13.9
			c0,0.2,0,0.4,0,0.7c0,13.2-10.7,23.9-23.9,23.8c-13.2,0-23.8-10.7-23.8-23.8L149.1,152.4L149.1,152.4z"/>
	</g>
</g>
</svg>
            <div class="lc-ttc-button-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 55 55">
                    <path class="border" d="M27.5,1A26.5,26.5,0,1,1,1,27.5,26.53007,26.53007,0,0,1,27.5,1m0-1A27.5,27.5,0,1,0,55,27.5,27.49989,27.49989,0,0,0,27.5,0Z" />
                    <path d="M28.773,27.49976l8.36328-8.36329A.90011.90011,0,1,0,35.86383,17.863l-8.36377,8.36377L19.13629,17.863a.90011.90011,0,1,0-1.27246,1.27343l8.36328,8.36329L17.86383,35.863a.90011.90011,0,1,0,1.27246,1.27343l8.36377-8.36376,8.36377,8.36376A.90011.90011,0,1,0,37.13629,35.863Z" />
                </svg>
            </div>
        </div>
        <div class="lc-content">
            <a class="lc-info_hotline" href=${'tel:' + localizations.companyNumber}>
                <svg class="lc-phone_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.65 24.88">
                <g>
                <g>
                    <g>
                        <path
                            d="M13.46 24.88c-1.35 0-2.79-.45-4.12-1.31-1.15-.74-4.07-2.9-6.44-6.98C.52 12.52.09 8.91.01 7.54c-.1-1.84.35-3.55 1.27-4.8C2.1 1.64 3.31.83 4.15.32 4.71-.02 5.38-.09 6 .11c.62.21 1.13.7 1.38 1.34.25.67.82 2.24 1.24 3.41L9 5.91c.69 1.91.63 2.5-1.14 3.33l-1.29.6c-.06.03-.18.09-.29.14 0 .02.02.05.03.08.47 1.31 1.03 2.52 1.65 3.59.97 1.65 2 2.87 2.29 3.2.02.03.05.05.07.07.06-.04.15-.1.26-.18.6-.43.79-.57 1.17-.83 1.62-1.14 2.14-.88 3.45.65.95 1.1 2.61 3.04 3.08 3.62.42.51.59 1.14.48 1.78-.11.64-.49 1.21-1.05 1.56-.86.54-2.24 1.17-3.57 1.32-.22.03-.45.04-.68.04zM5.3 1.8a.42.42 0 00-.21.06c-.71.43-1.73 1.1-2.35 1.95-.67.92-1 2.21-.92 3.63.07 1.23.46 4.5 2.64 8.24s4.82 5.7 5.86 6.37c1.2.77 2.49 1.12 3.61 1 1.03-.12 2.16-.64 2.82-1.06.12-.08.21-.2.23-.33a.403.403 0 00-.11-.35c-.33-.41-1.44-1.71-3.04-3.58-.22-.25-.44-.52-.59-.67-.13.08-.3.2-.46.31-.37.26-.57.4-1.16.82-.83.6-1.67 1.04-2.73-.17-.32-.36-1.45-1.69-2.5-3.49-.68-1.17-1.28-2.47-1.79-3.88-.54-1.5.26-2.02 1.19-2.46l1.29-.6c.16-.08.36-.17.5-.24-.06-.21-.18-.54-.29-.84l-.38-1.05c-.42-1.16-.98-2.72-1.23-3.38a.445.445 0 00-.28-.28c-.04-.01-.08-.02-.12-.02zm5.15 15.25zM16.31 13.16c-.12 0-.23-.03-.34-.09a.694.694 0 01-.27-.95c.51-.92.5-2.05-.03-2.96a2.977 2.977 0 00-2.56-1.48.69.69 0 01-.69-.71.7.7 0 01.7-.69c1.54.01 2.98.85 3.76 2.18.77 1.33.79 3 .04 4.34-.13.23-.37.36-.61.36z"
                            className="cls-1"></path>
                        <path
                            d="M19.09 14.33c-.12 0-.24-.03-.35-.1a.699.699 0 01-.25-.96c1.03-1.77 1.02-3.92-.04-5.73-1.03-1.77-2.91-2.86-4.92-2.86h-.05a.7.7 0 01-.7-.69c0-.39.31-.7.69-.71h.06c2.51 0 4.85 1.36 6.13 3.56 1.31 2.26 1.33 4.93.04 7.14-.13.22-.36.35-.6.35z"
                            className="cls-1"></path>
                    </g>
                </g>
                </g>
                </svg>
                <span class="lc-display_number">${localizations.companyDisplayNumber}</span>
            </a>
            <div class="lc-content-wrapper"></div>
        </div>`;
        document.body.appendChild(this.ttcPlaceHolder);
        this.lcContentElement = this.ttcPlaceHolder.querySelector('.lc-content .lc-content-wrapper');
    }

    private setupTTC(): void {
        const lcButtonClose = document.querySelector('.lc-ttc-button-close');

        if (lcButtonClose) {
            lcButtonClose.addEventListener('click', () => { this.tryToClose(this.closeOverlayModal.bind(this)); });
        }
    }

    private getSmakeElement(): Element {
        const variantObject = { id: this.smakeId };
        const userObject = { external_identifier: this.userId };
        const isPreview = this.context.releaseMode === 'Preview';

        let taxRateString = null;
        if (this.vatRate && this.vatRate > 0) {
            const taxRate = this.vatRate * 100;
            taxRateString = taxRate.toString();
        }

        const ttcElement = document.createElement('transfer-to-cart');

        ttcElement.setAttribute('token', this.smakeToken);
        ttcElement.setAttribute('locale', this.context.lang.split('-')[0]);
        ttcElement.setAttribute('preselected-variant-quantities', JSON.stringify(this.preselectedSmakeArticle));
        ttcElement.setAttribute('cart-items', JSON.stringify(this.addedArticles));
        ttcElement.setAttribute('available-variants', JSON.stringify(this.availableSizes));
        ttcElement.setAttribute('debug', isPreview.toString());
        ttcElement.setAttribute('tax-rate', taxRateString);
        ttcElement.setAttribute('id', this.ttcId);
        ttcElement.setAttribute('api-base-url', this.context.smakeEdgeApi);
        ttcElement.setAttribute('user-external-identifier', userObject.external_identifier);
        ttcElement.setAttribute('variant-id', variantObject.id.toString());
 
        return ttcElement;
    }
}
