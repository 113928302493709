export default class NotificationComponent {
    private static _instance: NotificationComponent;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public serviceNotAvailableMsg(msg: string): string {
        return `<div class='smake-not-available'>
            <span class='msg'>${msg}</span>
            </div>`
    }

    public checkSmakeAvailability(): void {
        // temp solution while we do not have NPM package.
        // after 2 seconds if smake designer will not be loaded service not available message will be visible

        setTimeout(() => {
            const smake_not_available_msg = document.querySelector('.smake-not-available');

            if (smake_not_available_msg)
                smake_not_available_msg.classList.add('show_msg');
        }, 4000);

    }
}
