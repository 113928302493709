import axios, { AxiosError, AxiosResponse } from 'axios';
import { IVariantArticles, IVariant, IBasketItems } from './article';
import { IContext } from '../types';

export class BasketService {
    private static _instance: BasketService;
    private context: IContext;

    public constructor() {
        this.context = window.__PP_LC_CONTEXT__;
    }

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public getArticles(): Promise<IBasketItems> {
        const apiUrl = this.context.baseUrl
            + 'api/basket/FastlaneProcessing/articles';

        return axios.get(apiUrl)
        .then((response: AxiosResponse) => response.status === 200 ? response.data : undefined)
        .catch((error: Error | AxiosError) => {
            const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;

            const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

            // eslint-disable-next-line no-console
            console.warn(
                `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
            );
        });
    }

    public postArticles(smakeVariantId: number, variants: Array<IVariant>) {
        const body: IVariantArticles = {
            smakeVariantId: smakeVariantId,
            variants
        }

        const apiUrl = this.context.baseUrl 
            + 'api/basket/FastlaneProcessing/articles';

        return axios.post(apiUrl, body)
            .then((response: AxiosResponse) => response.status === 200 ? response.data : undefined)
            .catch((error: Error | AxiosError) => {
                const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;

                const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

                // eslint-disable-next-line no-console
                console.warn(
                    `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
                );
            });
    }

    private isAxiosError(error): error is AxiosError {
        return typeof error.toJSON !== 'undefined';
    }
}
