import { ScrollLock } from '../helpers/scrollLock';
import { EsAppHelper } from '../helpers/esAppHelper';
import { DesignerOverlayModal } from '../designerOverlayModal/designerOverlayModal';
import { TransforToCartOverlayModal } from '../transforToCartOverlayModal/transforToCartOverlayModal';
import {
    IDesignerAppshellPayload, ICreatVariantRespons, ITTCObject, ISmakeArticle, IBasketModalPayload, IContext
} from '../types';
import { IVariant, IBasketItems } from '../basketService/article';
import { BasketService } from '../basketService/basketService';
import { LogoCreaterService } from '../logoCreaterService/logoCreaterService';
import { ConfirmationModal } from '../confirmationModal/confirmationModal';
import { NoAvailableSizeModal } from '../noAvailableSizeModal/noAvailableSizeModal';
import { ISmakevailableVariant } from '../logoCreaterService/logoCreaterService.d';

export class logoCreatorMiddleware {
    private readonly scrollLock: ScrollLock;
    private readonly esAppHelper: EsAppHelper;
    private static _instance: logoCreatorMiddleware;
    private designerOverlay: DesignerOverlayModal;
    private ttcOverlay: TransforToCartOverlayModal;
    private basketService: BasketService;
    private confirmationModal: ConfirmationModal;
    private noAvailableSizeModal: NoAvailableSizeModal; 
    private lcService: LogoCreaterService;
    private context: IContext;

    private masterArticleNo: number;
    private navKey: string;
    private navKeyPath: string;
    private userId: string;
    private withVat: boolean;
    private oneTimeToken: string;
    private preselectedArticles: Array<ISmakeArticle>;
    private alreadyAddToCart = false;
    private availableColors: Array<ISmakevailableVariant>;
    private cartItems: Array<string>;
    private smakeCampaignEntry = 'smake_campaign_entry';
    private passThroughItemOrign: string;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        this.context = window.__PP_LC_CONTEXT__;
        this.basketService = BasketService.instance;
        this.lcService = LogoCreaterService.instance;
        this.scrollLock = new ScrollLock();
        this.esAppHelper = new EsAppHelper();
        this.appShellEventBinding();
    }

    private appShellEventBinding(): void {
        // Open designer
        if (window && window.shell) {
            window.shell.subscribeTo('ESPP.LogoCreator.OpenDesignerWindow', (payload: IDesignerAppshellPayload) => {
                this.userId = this.context.companyNumber + `-${payload.userId}`;

                this.preselectedArticles = payload.preselectedArticles;
                this.masterArticleNo = payload.masterArticleNumber;
                this.navKey = payload.navKey;
                this.navKeyPath = payload.navKeyPath;
                this.withVat = payload.withVat;
                this.passThroughItemOrign = payload.itemOrigin;

                const savKeyStrings = payload.preselectedArticles[0].variant.external_identifier.split('-');
                const salesArticleNo = parseInt(savKeyStrings[0]);
                const colorCode = parseInt(savKeyStrings[1]);
                const ott = this.lcService.getOneTimeToken(this.userId);
                const colors = this.lcService.getAvailableColors(
                    this.masterArticleNo, salesArticleNo, colorCode, this.navKey,);
                const cartItems = this.getBasketItemSmakeIds();
                Promise.all([ott, colors, cartItems]).then(([ottResponse, colorSAVKeys, cartItemExternalIds]) => {
                    if (ottResponse && colorSAVKeys) {
                        this.oneTimeToken = ottResponse.token_string;

                        this.preselectedArticles.map((smakeArticle) => {
                            const savKey = smakeArticle.variant.external_identifier;
                            smakeArticle.variant.external_identifier =
                                `${payload.masterArticleNumber.toString()}#${savKey}`;
                        });

                        let smakeId: number = null;
                        const articleHasId = this.preselectedArticles.find((smakeArticle) => {
                            return smakeArticle.variant.id;
                        })
                        if (articleHasId) {
                            smakeId = articleHasId.variant.id;
                        }

                        this.availableColors = colorSAVKeys.map(sizeSAVkey =>
                            sizeSAVkey =
                            { external_identifier: `${this.masterArticleNo}#${sizeSAVkey.external_identifier}` });
                        this.cartItems = cartItemExternalIds;

                        if (this.designerOverlay) {
                            this.designerOverlay.update(
                                this.preselectedArticles[0].variant.external_identifier,
                                this.userId, this.oneTimeToken, this.availableColors, this.cartItems, smakeId);
                        } else {
                            this.designerOverlay = new DesignerOverlayModal(
                                this.preselectedArticles[0].variant.external_identifier,
                                this.userId,
                                this.oneTimeToken,
                                this.availableColors,
                                this.cartItems,
                                this.tryToCloseOverlay.bind(this),
                                smakeId);
                        }
                        this.showDesignerOverlayModal();
                    }
                });
            },
                'ESPP.LogoCreator.OpenDesignerWindow'
            );
            window.shell.publishTo('ESPP.LogoCreator.InitializationFinished', null);
        }
    }

    private showDesignerOverlayModal(): void {
        this.prepareToOpenModal();
        this.designerOverlay.showOverlayModal(this.oneTimeToken);

        // bind creation event
        const smake = document.querySelector('#smake-designer');
        smake.addEventListener('custom-variant-created', (payload: CustomEvent) => {
            if (payload.type === 'custom-variant-created') {
                const designerResponse: ICreatVariantRespons = payload.detail[0];
                const smakeId = designerResponse.id;
                const idArray = designerResponse.base.external_identifier.split('#');
                const newMasterArticleNo = parseInt(idArray[0]);

                if (!isNaN(newMasterArticleNo)) {
                    if (this.masterArticleNo !== newMasterArticleNo) {
                        this.masterArticleNo = newMasterArticleNo;
                        this.navKey = null;
                    }
                }

                const savKey = idArray.pop();
                const keys = savKey.split('-');
                const salesArticleNo = parseInt(keys[0]);
                const colorCode = parseInt(keys[1]);
                const basket = this.getBasketItems();
                const sizes = this.lcService.getAvailableSizes(
                    this.navKey, this.masterArticleNo, salesArticleNo, colorCode);
                const ottPromise = this.lcService.getOneTimeToken(this.userId);

                Promise.all([basket, sizes, ottPromise]).then(([psArticles, sizesSAVKeys, ott]) => {
                    if (sizesSAVKeys && ott) {
                        const vatRat = this.withVat ? Number(this.context.vatRate) : 0;
                        this.preselectedArticles = this.parsePreselectedArticle(sizesSAVKeys, smakeId);
                        this.oneTimeToken = ott.token_string;
                        const newSizesKeys = sizesSAVKeys.map(sizeSAVkey =>
                            sizeSAVkey =
                            { external_identifier: `${this.masterArticleNo}#${sizeSAVkey.external_identifier}` });

                        if (this.ttcOverlay) {
                            this.ttcOverlay.update(smakeId,
                                psArticles, newSizesKeys,
                                this.preselectedArticles, this.userId, this.oneTimeToken, vatRat);
                        } else {
                            this.ttcOverlay = new TransforToCartOverlayModal(
                                smakeId,
                                psArticles,
                                newSizesKeys,
                                this.preselectedArticles,
                                this.userId,
                                this.oneTimeToken,
                                vatRat,
                                this.tryToCloseOverlay.bind(this));
                        }

                        this.designerOverlay.closeOverlayModal();
                        this.designerOverlay.update(
                            designerResponse.base.external_identifier,
                            this.userId, this.oneTimeToken, this.availableColors, this.cartItems, smakeId);
                        this.ttcOverlay.showOverlayModal();
                        this.bindTTCEvent();
                    } else {
                        // show size modal:
                        if (!this.noAvailableSizeModal) {
                            this.noAvailableSizeModal = new NoAvailableSizeModal();
                        }
                        this.noAvailableSizeModal.showOverlayModal();
                    }
                });
            } else if (payload.type === 'error') {
                // error handling for smake error
            } else {
                // unknow error
            }
        });

        smake.addEventListener('session-expired', () => {
            this.lcService.getOneTimeToken(this.userId).then((ottResponse) => {
                if (ottResponse) {
                    this.oneTimeToken = ottResponse.token_string;
                    smake.setAttribute('token', this.oneTimeToken);
                }
            })
        });
    }

    private parsePreselectedArticle(newSizesKeys: ISmakevailableVariant[], smakeId: number): Array<ISmakeArticle> {
        const masterArticleNoString = this.preselectedArticles[0].variant.external_identifier.split('#')[0];
        const masterArticleNo = parseInt(masterArticleNoString);
        const id = this.preselectedArticles[0].variant.id;

        if (id !== smakeId) {
            this.preselectedArticles.map((article) => {
                article.variant.id = smakeId;
            });
        }

        if (masterArticleNo === this.masterArticleNo) {
            return this.preselectedArticles;
        } else {
            const newReturn: Array<ISmakeArticle> = [];
            this.preselectedArticles.forEach((article) => {
                const oldSavKey = article.variant.external_identifier.split('#')[1];
                const sizeCode = oldSavKey.split('-')[2];
                const matchedSize = newSizesKeys.find((size) => {
                    const newsizeCode = size.external_identifier.split('-')[2];
                    return newsizeCode === sizeCode;
                });
                if (matchedSize) {
                    newReturn.push(article);
                }
            });
            return newReturn;
        }
    }

    private bindTTCEvent() {
        const ttc = document.querySelector(`#${this.ttcOverlay.ttcId}`);
        ttc.addEventListener('add-to-cart', (payload: CustomEvent) => {
            const ttcResponse: ITTCObject = payload.detail[0];
            const variants: Array<IVariant> = [];
            let itemOrigin: string;

            if (this.passThroughItemOrign && this.passThroughItemOrign.length > 0) {
                itemOrigin = this.passThroughItemOrign;
            } else {
                itemOrigin = 'smake_designer';
                const smakeCampaignEntry = window.sessionStorage.getItem(this.smakeCampaignEntry);
                if (smakeCampaignEntry) {
                    itemOrigin += `/${smakeCampaignEntry}`;
                }
            }

            const basketModalpayload: IBasketModalPayload = {
                smakeId: this.ttcOverlay.smakeId,
                articles: [],
                masterArticleNumber: this.masterArticleNo,
                reporting: ttcResponse.reporting,
                itemOrigin: itemOrigin,
            };
            ttcResponse.cart_items.map((smakeArticle) => {
                const savKey = smakeArticle.variant.external_identifier.split('#').pop();
                const variant = {
                    salesArticleVariantKey: savKey,
                    quantity: smakeArticle.quantity,
                    itemOrigin: itemOrigin,
                    nativeNavKeyPath: this.navKeyPath,
                }
                const basketmodal = {
                    savKey: savKey,
                    quantity: smakeArticle.quantity
                }
                variants.push(variant);
                basketModalpayload.articles.push(basketmodal);
            });

            if (this.context.isBasketPage) {
                if (window && window.shell) {
                    if (!this.alreadyAddToCart) {
                        this.alreadyAddToCart = true;
                        window.shell.publishTo('ESPP.LogoCreator.FinishedDesign', {
                            smakeVariantId: ttcResponse.variant.id,
                            variants
                        });
                        window.shell.subscribeTo('ESSA.LogoCreator.CloseDesigner', () => {
                            this.ttcOverlay.closeOverlayModal();
                            this.afterCloseOverlay();
                            this.alreadyAddToCart = false;
                        }, 'ESPP.LogoCreator.Designer');
                    }
                } else {
                    this.ttcOverlay.closeOverlayModal();
                    this.afterCloseOverlay();
                }
            } else {

                this.basketService.postArticles(ttcResponse.variant.id, variants).then(() => {
                    const smakeCampaignEntry = window.sessionStorage.getItem(this.smakeCampaignEntry);
                    if (smakeCampaignEntry) {
                        window.sessionStorage.removeItem(this.smakeCampaignEntry);
                    }
                    this.ttcOverlay.closeOverlayModal();
                    if (window && window.shell) {
                        // update basket flyout
                        window.shell.publishTo('ESSA.BasketFlyout.BasketItemCountChanged', null);
                        // tell PDP to show basketModal
                        window.shell.publishTo('ESPP.LogoCreator.DidAddToBasket', basketModalpayload);
                    }
                    this.afterCloseOverlay();
                });
            }
        });

        // bind creation event
        ttc.addEventListener('abort', (payload: CustomEvent) => {
            if (payload.type === 'abort') {
                this.lcService.getOneTimeToken(this.userId).then((ottResponse) => {
                    if (ottResponse) {
                        this.oneTimeToken = ottResponse.token_string;
                    }
                    this.ttcOverlay.closeOverlayModal();
                    this.showDesignerOverlayModal();
                });
            } else if (payload.type === 'error') {
                // error handling for smake error
            } else {
                // unknow error
            }
        });

        // bind session expired
        ttc.addEventListener('session-expired', () => {
            this.lcService.getOneTimeToken(this.userId).then((ottResponse) => {
                if (ottResponse) {
                    this.oneTimeToken = ottResponse.token_string;
                    ttc.setAttribute('token', this.oneTimeToken);
                }
            })
        });
    }

    private afterCloseOverlay(): void {
        // unlock the screen:
        this.scrollLock.unlock();

        //show esApp navigation wheel
        this.esAppHelper.navigationWheel.show();

        // restore babbleBubble
        if (window.shell)
            window.shell.publishTo('esmkt.babblebubble.restore', null);
    }

    private tryToCloseOverlay(callBack: () => void): void {
        if (this.confirmationModal) {
            this.confirmationModal.update(callBack, this.afterCloseOverlay.bind(this));
        } else {
            this.confirmationModal = new ConfirmationModal(callBack, this.afterCloseOverlay.bind(this));
        }
        this.confirmationModal.showOverlayModal();
    }

    private getBasketItems(): Promise<ISmakeArticle[]> {
        return this.basketService.getArticles().then((data: IBasketItems) => {
            const smakeArticles: ISmakeArticle[] = [];

            if(data && data.articles.length > 0) {
                data.articles.map((article) => {
                    smakeArticles.push({
                        quantity: article.quantity,
                        variant: {
                            id: article.smakeVariantId,
                            external_identifier: article.masterArticleNo + '#' + article.salesArticleVariantKey
                        }
                    })
                });
            }

            return smakeArticles;
        });
    }

    private getBasketItemSmakeIds(): Promise<string[]> {
        return this.basketService.getArticles().then((data: IBasketItems) => {
            const savKeys: string[] = [];

            if (data && data.articles.length > 0) {
                data.articles.map((article) => {
                    savKeys.push(article.smakeVariantId.toString());
                });
            }

            return savKeys;
        });
    }

    private prepareToOpenModal() {
        // lock screen:
        this.scrollLock.lock();

        //hide esApp navigation wheel
        this.esAppHelper.navigationWheel.hide();

        // pop babblebubble
        setTimeout(() => {
            if (window.shell)
                window.shell.publishTo('esmkt.babblebubble.setlayer', 1051);
        }, 1500);
    }
}

logoCreatorMiddleware.instance;
