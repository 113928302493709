import 'custom-event-polyfill';

export class EsAppHelper {
    public navigationWheel: NavigationWheel;
    constructor() {
        this.navigationWheel = new NavigationWheel();
    }
}

class NavigationWheel {
    private visible: boolean;
    constructor() {
        this.visible = true;
    }

    public isVisible() {
        return this.visible;
    }

    public show() {
        if (window) {
            const showNavWheelEvent = new CustomEvent('esapp.navigationwheel', {
                detail: {
                    visible: true,
                },
            });
            this.visible = true;
            document.dispatchEvent(showNavWheelEvent);
        }
    }
    
    public hide() {
        if (window) {
            const hideNavWheelEvent = new CustomEvent('esapp.navigationwheel', {
                detail: {
                    visible: false,
                },
            });
            this.visible = false;
            document.dispatchEvent(hideNavWheelEvent);
        }
    }
}
