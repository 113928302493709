import axios, { AxiosError, AxiosResponse } from 'axios';
import { ISizeType, ISmakevailableVariant, IOneTimeTokenResponse } from './logoCreaterService.d';
import { IContext } from '../types';

export class LogoCreaterService {
    private static _instance: LogoCreaterService;
    private context: IContext;
    private getAvailableSizeServicePath = 'api/logocreator/getAvailableSize';
    private getAvailableColorServicePath = 'api/logocreator/getAvailableColor';
    private getOneTimeTokenServicePath = 'api/logocreator/getott';
    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        this.context = window.__PP_LC_CONTEXT__;
    }

    public getAvailableSizes(
        navigationKey: string,
        masterArticleNo: number,
        salesArticleNo: number,
        colorCode: number): Promise<Array<ISmakevailableVariant> | void> {
        const apiUrl = this.context.baseUrl
            + this.getAvailableSizeServicePath;
        const url = new URL(apiUrl);
        url.searchParams.append('salesArticleNo', salesArticleNo.toString());
        url.searchParams.append('masterArticleNo', masterArticleNo.toString());
        url.searchParams.append('colorCode', colorCode.toString());
        if (navigationKey) {
            url.searchParams.append('navigationkey', navigationKey);
        }
        return axios.get(url.toString())
            .then((response: AxiosResponse) => {
                if (response.status !== 200)
                    Promise.reject(new Error(`fail to get size with status code ${response.status}`));

                const sizeTypes = response.data as Array<ISizeType>;
                const avaialbeSAVKeys: Array<ISmakevailableVariant> = [];
                sizeTypes.map((sizeType) => {
                    sizeType.sizes.map((size) => {
                        if (size.salesArticleVariantKey?.length > 0)
                            avaialbeSAVKeys.push({
                                external_identifier: size.salesArticleVariantKey
                            });
                    })
                })
                return avaialbeSAVKeys;
            })
        .catch((error: Error | AxiosError) => {
            const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;

            const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

            // eslint-disable-next-line no-console
            console.warn(
                `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
            );
        });
    }

    public getOneTimeToken(userId: string): Promise<IOneTimeTokenResponse | void> {
        const apiUrl = this.context.baseUrl
            + this.getOneTimeTokenServicePath;
        const url = new URL(apiUrl);
        url.searchParams.append('userId', userId);
        return axios.get(url.toString())
            .then((response: AxiosResponse) => {
                if (response.status !== 200)
                    Promise.reject(new Error(`fail to get size with status code ${response.status}`));

                const ottResponse = response.data as IOneTimeTokenResponse;
                // TODO: check time expire
                return ottResponse;
            })
            .catch((error: Error | AxiosError) => {
                const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;

                const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

                // eslint-disable-next-line no-console
                console.warn(
                    `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
                );
            });
    }

    public getAvailableColors(
        masterArticleNo: number,
        salesArticleNo: number,
        colorCode: number,
        navigationKey?: string): Promise<Array<ISmakevailableVariant> | void> {
        const apiUrl = this.context.baseUrl
            + this.getAvailableColorServicePath;
        const url = new URL(apiUrl);
        url.searchParams.append('salesArticleNo', salesArticleNo.toString());
        url.searchParams.append('masterArticleNo', masterArticleNo.toString());
        url.searchParams.append('colorCode', colorCode.toString());
        if (navigationKey) {
            url.searchParams.append('navigationkey', navigationKey);
        }
        return axios.get(url.toString())
            .then((response: AxiosResponse) => {
                if (response.status !== 200)
                    Promise.reject(new Error(`fail to get size with status code ${response.status}`));

                const avaialbeColorSAVKeys = response.data as Array<string>;
                const results: Array<ISmakevailableVariant> = [];
                avaialbeColorSAVKeys.forEach((colorSAVKey) => {
                    results.push({
                        external_identifier: colorSAVKey
                    });
                })
                return results;
            })
            .catch((error: Error | AxiosError) => {
                const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;

                const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

                // eslint-disable-next-line no-console
                console.warn(
                    `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
                );
            });
    }

    private isAxiosError(error): error is AxiosError {
        return typeof error.toJSON !== 'undefined';
    }
}
