/* eslint-disable max-len */
import { IContext, ILocalization } from '../types.d';

export class NoAvailableSizeModal {
    private confirmPlaceHolder: HTMLElement;
    private assetsLoaded: boolean;
    private context: IContext;

    public constructor() {
        this.assetsLoaded = false;
        this.context = window.__PP_LC_CONTEXT__;
    }

    public showOverlayModal(): void {
        if (!this.assetsLoaded) {
            // append main window:
            this.createDesignerElement();
            this.assetsLoaded = true;
        } else {
            this.confirmPlaceHolder.classList.toggle('lc-overlay-closed', false);
        }
    }

    private createDesignerElement(): void {
        const localizations: ILocalization = this.context.localization;
        this.confirmPlaceHolder = document.createElement('div');
        this.confirmPlaceHolder.classList.add('lc-confirm-background');

        this.confirmPlaceHolder.innerHTML = `<div class="lc-confirm-modal">
                <div class="lc-confirm-header"><span>${localizations.notic}</span></div>
                <div class="lc-confirm-content"><span>${localizations.noAvailableSize}</span></div>
                <div class="lc-confirm-footer">
                    <button class="lc-confirm-button lc-confirm-close">${localizations.close}</button>
                </div>
            </div>`;

        document.body.appendChild(this.confirmPlaceHolder);

        const lcConfirmButtons = document.querySelectorAll('.lc-confirm-button');

        if (lcConfirmButtons.length > 0) {
            lcConfirmButtons.forEach((button) => {
                button.addEventListener('click', () => {
                    this.confirmPlaceHolder.classList.toggle('lc-overlay-closed', true);
                })
            });
        }
    }
}
